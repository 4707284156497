import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22a76883"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "onLoad"]
const _hoisted_2 = {
  key: 1,
  class: "h-64 w-64 rounded-lg border-2 border-gray-300 bg-gray-200 flex items-center justify-center"
}
const _hoisted_3 = ["href", "download"]
const _hoisted_4 = {
  key: 0,
  class: "flex gap-3 items-center"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "w-64 h-16 flex items-center justify-center"
}
const _hoisted_7 = ["accept"]

import { XCircleIcon, PaperClipIcon } from '@heroicons/vue/24/outline'
import { ref, onMounted, reactive, computed } from 'vue'
import { Sortable } from '@shopify/draggable'
import Cookies from 'js-cookie'
import { mapRelationships } from '@/store/mappers'
import { useStore } from 'vuex'


export default {
  __name: 'ImageUploaderNeue',
  props: {
  files: {},
  multiple: { default: true },
  displayMultiple: { default: props => props.multiple },
  images: { default: true },
  retained: { default: false }
},
  emits: ['change'],
  setup(__props, { emit: __emit }) {

const store = useStore()
const props = __props
const emit = __emit

const files = computed(() =>
  props.retained
    ? mapRelationships(retainer.value.filter(x => !x._destroy).map(x => ({ id: x.id ?? x._id, type: 'attachment' })))
    : props.files
)

const uploader = ref(null)
const sortable = ref(null)
const uploading = ref(false)
const imageLoaded = reactive({})
const retainer = ref([])

onMounted(() => {
  if (props.retained) retainer.value = props.files.map((x, i) => ({ id: x.id, order: i }))
  if (!props.displayMultiple) return
  new Sortable(sortable.value, { distance: 1 }).on('sortable:stop', e => {
    if (e.data.oldIndex === e.data.newIndex) return
    const list = files.value
    const moved = list.splice(e.data.oldIndex, 1)[0]
    list.splice(e.data.newIndex, 0, moved)
    for (const file of retainer.value) {
      file.order = list.findIndex(x => x.id === file.id || x._id === file.id)
    }
    emit('change', props.retained ? retainer.value : list.map((x, i) => ({ id: x.id, order: i })))
  })
})

const uploadFile = async file => {
  uploading.value = true
  uploader.value.value = null

  const form = new FormData()
  form.append('attachment[file]', file)

  const attachment = await fetch(process.env.VUE_APP_API_SERVER + 'upload_attachments', {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + Cookies.get('auth_token')
    },
    body: form
  }).then(x => x.json())

  store.commit('RECEIVE_OBJECT', attachment)

  if (attachment.data.attributes.attachment_type === 'file') {
    imageLoaded[attachment.data.id] = true
    uploading.value = false
  }

  const order = files.value.reduce((max, x) => Math.max(max, x.attributes.order), -1) + 1

  retainer.value.push({ _id: attachment.data.id, order })
  if (!props.multiple) emit('change', { _id: attachment.data.id })
  else emit('change', props.retained ? retainer.value : [{ _id: attachment.data.id, order }])
}

const deleteFile = file => {
  if (retainer.value.find(x => x.id === file.id)) retainer.value.find(x => x.id === file.id)._destroy = true
  retainer.value = retainer.value.filter(x => x._id !== file.id)
  if (!props.multiple) emit('change', { _destroy: true })
  else emit('change', props.retained ? retainer.value : [{ id: file.id, _destroy: true }])
}

return (_ctx, _cache) => {
  const _component_NeueButton = _resolveComponent("NeueButton")
  const _component_BasicSpinner = _resolveComponent("BasicSpinner")

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "flex flex-wrap gap-2 mb-4",
        ref_key: "sortable",
        ref: sortable
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files.value, (file) => {
          return (_openBlock(), _createElementBlock("div", {
            key: file.id,
            class: _normalizeClass(["relative h-fit draggable-source", props.displayMultiple && 'cursor-move'])
          }, [
            (file.attributes.attachment_type !== 'file')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: file.attributes.secure_url,
                  class: "h-64 rounded-md",
                  onLoad: 
              () => {
                uploading.value = false
                imageLoaded[file.id] = true
              }
            
                }, null, 40, _hoisted_1))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("a", {
                    class: "flex items-center gap-1",
                    href: file.attributes.shareable_url,
                    download: file.attributes.original_filename
                  }, [
                    _createVNode(_unref(PaperClipIcon), { class: "h-8 w-8" }),
                    _createElementVNode("span", null, _toDisplayString(file.attributes.original_filename), 1)
                  ], 8, _hoisted_3)
                ])),
            (imageLoaded[file.id] || file.attributes.attachment_type === 'file')
              ? (_openBlock(), _createBlock(_unref(XCircleIcon), {
                  key: 2,
                  class: "h-6 w-6 cursor-pointer absolute top-1 right-1 rounded-full bg-white z-10 draggable-invisible",
                  onClick: $event => (deleteFile(file))
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ], 512),
      (props.displayMultiple)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_NeueButton, {
              type: "secondary",
              onClick: _cache[0] || (_cache[0] = $event => (uploader.value.click()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("+ " + _toDisplayString(props.images ? 'Image' : 'File'), 1)
              ]),
              _: 1
            }),
            (uploading.value)
              ? (_openBlock(), _createBlock(_component_BasicSpinner, {
                  key: 0,
                  size: "custom",
                  class: "h-8 w-8"
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, files.value.length]
    ]),
    (!files.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["h-64 w-64 rounded-lg border-2 border-gray-300 border-dashed flex items-center justify-center", !uploading.value && 'cursor-pointer']),
            onClick: _cache[1] || (_cache[1] = $event => (!uploading.value && uploader.value.click()))
          }, [
            (uploading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_BasicSpinner, { size: "medium" })
                ]))
              : _createCommentVNode("", true),
            (!uploading.value)
              ? (_openBlock(), _createBlock(_component_NeueButton, {
                  key: 1,
                  type: "secondary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("+ " + _toDisplayString(props.images ? 'Image' : 'File'), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "file",
      ref_key: "uploader",
      ref: uploader,
      class: "hidden",
      onChange: _cache[2] || (_cache[2] = e => uploadFile(e.target.files[0])),
      accept: props.images ? 'image/*' : '*'
    }, null, 40, _hoisted_7)
  ]))
}
}

}