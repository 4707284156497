import script from "./SettingsTab.vue?vue&type=script&setup=true&lang=js"
export * from "./SettingsTab.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import DescriptionList from "@/components/neue/DescriptionList.vue";
import DescriptionListDropdown from "@/components/neue/DescriptionListDropdown.vue";
import DescriptionListEditableInput from "@/components/neue/DescriptionListEditableInput.vue";
import DescriptionListItem from "@/components/neue/DescriptionListItem.vue";
import NeueButton from "@/components/neue/NeueButton.vue";
import SaveBar from "@/components/SaveBar.vue";
import SecondaryNav from "@/components/SecondaryNav.vue";
import SecondaryTop from "@/components/SecondaryTop.vue";
script.components = Object.assign({}, { DescriptionList, DescriptionListDropdown, DescriptionListEditableInput, DescriptionListItem, NeueButton, SaveBar, SecondaryNav, SecondaryTop }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { DescriptionList, DescriptionListDropdown, DescriptionListEditableInput, DescriptionListItem, NeueButton, SaveBar, SecondaryNav, SecondaryTop }, script.__o.components);}