<template>
  <SecondaryNav>
    <template #sidebar>
      <SellSidebar />
    </template>
    <template #top>
      <SecondaryTop
        :text="vLocal.name"
        :parent="{ name: 'Product library', link: '/store/products' }"
        :save-status="true"
      />
      <SaveBar
        :has-unsaved-changes="vLocal.status.hasUnsavedChanges"
        @on-discard="handleDiscardChanges()"
        @on-save="handleSubmit()"
      />
    </template>
    <template #main>
      <div class="flex flex-col justify-between h-full">
        <Form
          class="flex flex-1 flex-col justify-between"
          @submit.prevent="handleSubmit()"
          @keydown.meta.enter.exact="handleSubmit()"
          @keydown.enter="handleKeydown($event)"
        >
          <PageGrid>
            <MainCol>
              <!-- General Detail -->
              <BasicContainer>
                <div class="grid grid-cols-12 gap-6">
                  <div class="col-span-12">
                    <label for="product-title" class="block text-sm font-medium text-gray-700 mb-1">Title</label>
                    <input
                      id="product-title"
                      v-model="vLocal.name"
                      type="text"
                      name="product-title"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      @input="vLocal.status.hasUnsavedChanges = true"
                    />
                  </div>
                  <div class="col-span-12">
                    <label for="product-description" class="block text-sm font-medium text-gray-700 mb-1">
                      Description
                    </label>
                    <textarea
                      id="product-description"
                      v-model="vLocal.description"
                      name="product-description"
                      rows="8"
                      class="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                      @input="vLocal.status.hasUnsavedChanges = true"
                    ></textarea>
                  </div>

                  <div class="col-span-6">
                    <label for="product-sku" class="block text-sm font-medium text-gray-700 mb-1">SKU</label>
                    <input
                      id="product-sku"
                      v-model="vLocal.sku"
                      type="text"
                      name="product-sku"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-mono"
                      @input="vLocal.status.hasUnsavedChanges = true"
                    />
                  </div>

                  <div class="col-span-6">
                    <label for="product-price" class="block text-sm font-medium text-gray-700 mb-1">Base price</label>
                    <div class="flex rounded-md shadow-sm">
                      <span
                        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                      >
                        $
                      </span>
                      <input
                        id="default-price"
                        v-model="vLocal.defaultPrice"
                        type="text"
                        inputmode="numeric"
                        pattern="[0-9]*.?[0-9]*"
                        name="product-price"
                        class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        @input="vLocal.status.hasUnsavedChanges = true"
                      />
                    </div>
                  </div>

                  <div class="col-span-6">
                    <label
                      for="product-unique_quantity_name_single"
                      class="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Quantity title for a single item
                    </label>
                    <input
                      id="product-unique_quantity_name_single"
                      v-model="vLocal.uniqueQuantityNameSingle"
                      type="text"
                      name="product-unique_quantity_name_single"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Leave blank for default"
                      @input="vLocal.status.hasUnsavedChanges = true"
                    />
                  </div>

                  <div class="col-span-6">
                    <label for="product-starting_quantity" class="block text-sm font-medium text-gray-700 mb-1">
                      Starting (minimum) quantity
                    </label>
                    <input
                      id="product-starting_quantity"
                      v-model="vLocal.startingQuantity"
                      type="number"
                      name="product-starting_quantity"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-mono"
                      placeholder="Default"
                      @input="vLocal.status.hasUnsavedChanges = true"
                      min="1"
                      step="1"
                      pattern="\d+"
                    />
                  </div>

                  <div class="col-span-6">
                    <label for="product-step" class="block text-sm font-medium text-gray-700 mb-1">Quantity step</label>
                    <input
                      id="product-step"
                      v-model="vLocal.step"
                      type="number"
                      name="product-step"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-mono"
                      placeholder="Default"
                      @input="vLocal.status.hasUnsavedChanges = true"
                      min="1"
                      step="1"
                      pattern="\d+"
                    />
                  </div>

                  <div class="col-span-6">
                    <label for="product-price" class="block text-sm font-medium text-gray-700 mb-1">Tax code</label>
                    <button-dropdown
                      :width="'full'"
                      class="mt-2 block w-full"
                      :button-text="`${
                        allProductTaxCodes[indexForSelectedTaxOption(allProductTaxCodes)]?.name
                      } ${taxCodeForProduct(allProductTaxCodes[indexForSelectedTaxOption(allProductTaxCodes)])} (${
                        allProductTaxCodes[indexForSelectedTaxOption(allProductTaxCodes)]?.description
                      })`"
                      :show-search="true"
                      @searchInputChanged="taxSearchInputChanged"
                    >
                      <div v-for="(object, index) in allProductTaxCodes" :key="index">
                        <button
                          type="button"
                          class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
                          role="menuitem"
                          @click="taxOptionSelected(object)"
                        >
                          {{ `${object.name} ${taxCodeForProduct(object)} (${object.description})` }}
                        </button>
                      </div>
                    </button-dropdown>
                  </div>
                  <div v-if="vLocal.type === 'GiftCardProduct'" class="col-span-6">
                    <label for="account-credit-value" class="block text-sm font-medium text-gray-700 mb-1">
                      Account credit value
                    </label>
                    <div class="flex rounded-md shadow-sm">
                      <span
                        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                      >
                        $
                      </span>
                      <input
                        id="account-credit-value"
                        v-model="vLocal.accountCreditValue"
                        type="text"
                        inputmode="numeric"
                        pattern="[0-9]*.?[0-9]*"
                        name="account-credit-value"
                        class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        @input="vLocal.status.hasUnsavedChanges = true"
                      />
                    </div>
                  </div>
                  <div class="col-span-6">
                    <FormLabel for="newLimit">
                      Overall inventory available
                      <InformationCircleIcon
                        v-tippy="{
                          content:
                            'The amount this product can be ordered before being marked \'out of stock\' - must be reset manually'
                        }"
                        class="w-4 h-4 inline"
                      />
                    </FormLabel>
                    <button
                      id="newLimit"
                      name="newLimit"
                      :class="[
                        `mt-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
                        vLocal.hasQuantityLimit ? 'bg-blue-600' : 'bg-gray-200'
                      ]"
                      type="button"
                      role="switch"
                      aria-labelledby="newLimit"
                      @click="
                        () => {
                          vLocal.hasQuantityLimit = !vLocal.hasQuantityLimit
                          vLocal.status.hasUnsavedChanges = true
                        }
                      "
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`,
                          vLocal.hasQuantityLimit ? 'translate-x-5' : 'translate-x-0'
                        ]"
                      ></span>
                    </button>
                  </div>
                  <div v-show="vLocal.hasQuantityLimit" class="col-span-12">
                    <label for="new-fulfillment-qty" class="block text-sm font-medium text-gray-700">
                      Quantity Limit
                    </label>
                    <div class="mt-1">
                      <input
                        id="new-fulfillment-qty"
                        v-model="vLocal.quantityLimit"
                        type="number"
                        name="new-fulfillment-qty"
                        class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        @input="vLocal.status.hasUnsavedChanges = true"
                      />
                    </div>
                  </div>
                  <div class="col-span-6">
                    <FormLabel for="requiresFulfillment" label="Requires a fulfillment method at checkout" />
                    <button
                      id="requiresFulfillment"
                      name="requiresFulfillment"
                      :class="[
                        `mt-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
                        vLocal.requiresFulfillment ? 'bg-blue-600' : 'bg-gray-200'
                      ]"
                      type="button"
                      role="switch"
                      aria-labelledby="requiresFulfillment"
                      @click="
                        () => {
                          vLocal.requiresFulfillment = !vLocal.requiresFulfillment
                          vLocal.status.hasUnsavedChanges = true
                        }
                      "
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`,
                          vLocal.requiresFulfillment ? 'translate-x-5' : 'translate-x-0'
                        ]"
                      ></span>
                    </button>
                  </div>
                </div>
              </BasicContainer>
              <!-- Fallback product -->
              <BasicContainer>
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12">
                    <div class="flex justify-between">
                      <header>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Autofill Replacement</h3>
                        <div class="text-sm text-gray-700 mb-4">
                          {{
                            vLocal.fallbackProduct
                              ? `When ${vLocal.name} is unavailable, this product will be displayed in
                stores instead`
                              : `Select other product for replacement when ${vLocal.name} is not available for autofill (must be displayed in store at time of replacement)`
                          }}
                        </div>
                      </header>
                      <div>
                        <button
                          v-show="!vLocal.fallbackProduct"
                          class="btn-primary"
                          @click.prevent="addFallbackProduct()"
                        >
                          +&nbsp;Replacement
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Fallback Product Modal -->
                  <TheModal
                    v-if="vLocal.modal.type === 'fallbackProductModal'"
                    :is-open="vLocal.modal.type === 'fallbackProductModal'"
                    modal-title="Autofill Replacement"
                    :modal-description="`Select other product for replacement when ${vLocal.name} is not available for autofill (must be displayed in store at time of replacement)`"
                    @close-modal="local.modal.type = ''"
                  >
                    <template #main>
                      <Form
                        class="grid grid-cols-12 gap-4"
                        @submit.prevent="saveFallbackProduct(vLocal.modal.content.fallbackProduct)"
                      >
                        <div class="col-span-12">
                          <InlineSearchSelect
                            search-endpoint="products/search"
                            :search-request="{ query: vLocal.modal.searchQuery }"
                            :helper-text="searchSelectHelperText"
                            @on-search="handleProductSearchQuery"
                            @on-results="handleProductSearchResults"
                          >
                            <template #searchResults>
                              <MenuItem v-for="item in vLocal.modal.searchResults" :key="item.id" class="z-10">
                                <button
                                  :class="[
                                    vLocal.modal.content.fallbackProduct.some(x => x.id == item.id)
                                      ? 'text-gray-300'
                                      : 'text-gray-700',
                                    'group rounded-md w-full px-2 py-2 text-sm text-left hover:bg-gray-100 duration-200 transition-colors'
                                  ]"
                                  :disabled="vLocal.modal.content.fallbackProduct.some(x => x.id == item.id)"
                                  @click.prevent="handleProductSearchSelected(item)"
                                  class="inline-flex gap-2"
                                >
                                  <div v-if="image(item)" class="h-8 w-8 flex-shrink-0">
                                    <img
                                      class="w-full h-full object-center object-cover rounded select-none"
                                      :src="image(item)"
                                    />
                                  </div>
                                  <div>
                                    <span class="font-bold">
                                      {{ item.attributes.product_name }}
                                    </span>
                                    <span v-if="item.attributes.description" class="text-xs text-gray-500 ml-1">
                                      —
                                      {{ item.attributes.description }}
                                    </span>
                                  </div>
                                </button>
                              </MenuItem>
                            </template>
                            <template #selectedItems>
                              <ul>
                                <li
                                  v-for="item in vLocal.modal.content.fallbackProduct"
                                  :key="item.id"
                                  class="group py-2 mt-2 px-2 duration-150 transition-colors hover:bg-gray-100"
                                >
                                  <div class="flex">
                                    <CheckIcon class="w-4 h-4 rounded-full mr-2 text-gray-700" />
                                    <div class="flex space-x-4 flex-1">
                                      <div class="text-left text-gray-700">
                                        <p class="text-sm font-medium leading-none">
                                          {{ item.attributes.product_name }}
                                        </p>
                                        <p class="text-sm text-gray-500">
                                          <span class="text-xs text-gray-500">
                                            {{ item.attributes.description }}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <button @click.prevent="handleProductSearchRemoveSelected()">
                                      <XMarkIcon class="w-4 h-4 rounded-full mr-2 text-gray-700" />
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </template>
                          </InlineSearchSelect>
                        </div>
                      </Form>
                    </template>
                    <template #footer>
                      <button
                        class="btn-primary justify-center mr-3"
                        type="button"
                        @click="saveFallbackProduct(vLocal.modal.content.fallbackProduct)"
                      >
                        Save
                      </button>
                      <button class="btn-ghost justify-center" type="button" @click="resetModalContent()">
                        Cancel
                      </button>
                    </template>
                  </TheModal>
                  <div v-if="vLocal.fallbackProduct" class="col-span-12">
                    <div class="p-3 transition-colors duration-150 hover:bg-gray-50">
                      <div class="group flex space-x-4">
                        <div class="flex-1 min-w-0">
                          <div class="text-gray-900 text-left">
                            <p class="text-sm font-medium max-w-xs truncate">
                              {{ vLocal.fallbackProduct.attributes.product_name }}
                            </p>
                            <p
                              v-show="vLocal.fallbackProduct.attributes.description"
                              class="text-sm text-gray-500 max-w-sm truncate"
                            >
                              {{ vLocal.fallbackProduct.attributes.description }}
                            </p>
                          </div>
                        </div>
                        <div>
                          <button
                            v-tippy="{ content: 'Remove' }"
                            class="ml-2 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 opacity-0 group-hover:opacity-100 text-gray-500 hover:text-blue-600"
                            @click.prevent="saveFallbackProduct(null)"
                          >
                            <BasicSpinner v-if="vLocal.status.fallbackProduct.isLoading" color="gray-700" />
                            <XMarkIcon v-else class="h-4 w-4" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BasicContainer>
              <BasicContainer>
                <div class="flex justify-between mb-3">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">Variants</h3>
                  <Button :is-primary="true" @click.prevent="addVariant">+ Variant</Button>
                </div>
                <TheModal
                  v-if="optionModal.visible"
                  :is-open="optionModal.visible"
                  modal-title="Variant option"
                  @close-modal="optionModal.visible = false"
                >
                  <template #main>
                    <div class="grid grid-cols-12 gap-6">
                      <div class="col-span-12">
                        <label for="new-option-name" class="block text-sm font-medium text-gray-700">Title</label>
                        <div class="mt-1">
                          <input
                            id="new-option-name"
                            v-model="optionModal.name"
                            type="text"
                            name="new-option-name"
                            class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div class="col-span-12">
                        <label for="new-option-match-name" class="block text-sm font-medium text-gray-700">
                          Title for matching (optional, not shown to customer)
                        </label>
                        <div class="mt-1">
                          <input
                            id="new-option-match-name"
                            v-model="optionModal.matchName"
                            type="text"
                            name="new-option-match-name"
                            class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div class="col-span-12">
                        <label for="new-option-description" class="block text-sm font-medium text-gray-700">
                          Description (optional)
                        </label>
                        <div class="mt-1">
                          <input
                            id="new-option-description"
                            v-model="optionModal.description"
                            type="text"
                            name="new-option-description"
                            class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div class="col-span-12">
                        <label for="new-option-price" class="block text-sm font-medium text-gray-700">Price</label>
                        <div class="max-w-lg flex rounded-md shadow-sm mt-1">
                          <span
                            class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                          >
                            <CurrencyDollarIcon class="h-4 w-4" />
                          </span>
                          <input
                            id="new-option-price"
                            v-model="optionModal.price"
                            type="text"
                            name="new-option-price"
                            class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #footer>
                    <button class="btn-primary justify-center mr-3" type="button" @click.prevent="saveOption">
                      Save
                    </button>
                    <button class="btn-ghost justify-center" type="button" @click.prevent="resetOptionModal">
                      Cancel
                    </button>
                  </template>
                </TheModal>
                <TheModal
                  v-show="variantModal.visible"
                  :is-open="variantModal.visible"
                  modal-title="Product variant"
                  @close-modal="variantModal.visible = false"
                >
                  <template #main>
                    <div class="grid grid-cols-12 gap-6">
                      <div class="col-span-12">
                        <label for="new-variant-name" class="block text-sm font-medium text-gray-700">Title</label>
                        <div class="mt-1">
                          <input
                            id="new-variant-name"
                            v-model="variantModal.name"
                            type="text"
                            name="new-variant-name"
                            class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div class="col-span-12">
                        <label for="new-variant-required" class="block text-sm font-medium text-gray-700">
                          Required
                        </label>
                        <div class="w-4 h-4 mr-2">
                          <input
                            id="new-variant-required"
                            v-model="variantModal.required"
                            name="new-variant-required"
                            type="checkbox"
                            class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                          />
                        </div>
                      </div>
                      <div class="col-span-12">
                        <label for="new-variant-quantity-option" class="block text-sm font-medium text-gray-700">
                          Quantity option
                        </label>
                        <div class="mt-4 space-y-4" name="new-variant-quantity-option">
                          <div class="flex items-center">
                            <input
                              id="choose-one"
                              v-model="variantModal.quantity"
                              name="choose-one"
                              type="radio"
                              value="choose_one"
                              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                            />
                            <label for="choose-one" class="block ml-3 text-gray-700 text-sm font-medium">
                              Choose one
                            </label>
                          </div>
                          <div class="flex items-center">
                            <input
                              id="choose-many"
                              v-model="variantModal.quantity"
                              name="choose-many"
                              value="choose_many"
                              type="radio"
                              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                            />
                            <label for="choose-many" class="block ml-3 text-gray-700 text-sm font-medium">
                              Choose many
                            </label>
                          </div>
                          <div class="flex items-center">
                            <input
                              id="custom-quantities"
                              v-model="variantModal.quantity"
                              name="custom-quantities"
                              value="custom_quantities"
                              type="radio"
                              class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                            />
                            <label for="custom-quantities" class="block ml-3 text-gray-700 text-sm font-medium">
                              Custom quantities (bundle)
                            </label>
                          </div>
                        </div>
                      </div>
                      <div v-show="variantModal.quantity == 'custom_quantities'" class="col-span-12">
                        <label for="minimum" class="block text-sm font-medium text-gray-700">
                          Minimum allowed to select
                        </label>
                        <div class="mt-1">
                          <input
                            id="minimum"
                            v-model="variantModal.minimum"
                            type="text"
                            name="minimum"
                            class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div v-show="variantModal.quantity == 'custom_quantities'" class="col-span-12">
                        <label for="maximum" class="block text-sm font-medium text-gray-700">
                          Maximum allowed to select
                        </label>
                        <div class="mt-1">
                          <input
                            id="maximum"
                            v-model="variantModal.maximum"
                            type="text"
                            name="maximum"
                            class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div class="col-span-12">
                        <label for="new-variant-customer-visible" class="block text-sm font-medium text-gray-700">
                          Is visible to the customer
                        </label>
                        <div class="w-4 h-4 mr-2">
                          <input
                            id="new-variant-customer-visible"
                            v-model="variantModal.customerVisible"
                            name="new-variant-customer-visible"
                            type="checkbox"
                            class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #footer>
                    <button class="btn-primary justify-center mr-3" type="button" @click.prevent="saveVariant">
                      Save
                    </button>
                    <button class="btn-ghost justify-center" type="button" @click.prevent="resetVariantModal">
                      Cancel
                    </button>
                  </template>
                </TheModal>
                <div v-if="vVariants.length">
                  <Draggable :model-value="vLocal.variants || vVariants" item-key="id" @end="dragVariant">
                    <template #item="{ element: variant }">
                      <div class="hover:bg-gray-50 transition duration-150 border-t pt-4 px-2 cursor-move">
                        <div class="flex justify-between mb-1">
                          {{ variant.attributes.name }}
                          <div class="sm:w-1/3 text-right">
                            <span class="relative z-0 inline-flex shadow-sm rounded-md">
                              <button
                                type="button"
                                class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                @click.prevent="removeVariant(variant)"
                              >
                                Remove
                              </button>
                              <button
                                type="button"
                                class="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                @click.prevent="editVariant(variant)"
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                @click.prevent="addOption(variant)"
                              >
                                Add Option
                              </button>
                            </span>
                          </div>
                        </div>
                        <div v-if="vOptions(variant).length" class="ml-4 mt-3">
                          <Draggable
                            :model-value="vLocal.options[variant.id] || vOptions(variant)"
                            item-key="id"
                            @end="e => dragOption(variant, e)"
                          >
                            <template #item="{ element: option }">
                              <div class="group border-t border-gray-100 py-2 pt-2 sm:grid sm:grid-cols-2 sm:gap-2">
                                <span
                                  class="text-sm font-medium text-gray-500 cursor-pointer"
                                  @click.prevent="editOption(option)"
                                >
                                  {{ option.attributes.name }}
                                  <span v-if="option.attributes.price.cents">
                                    ({{ option.attributes.price.format }})
                                  </span>
                                </span>
                                <div class="text-right">
                                  <button
                                    v-tippy="{ content: 'Edit' }"
                                    class="ml-2 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 opacity-0 group-hover:opacity-100 text-gray-500 hover:text-blue-600"
                                    @click.prevent="editOption(option)"
                                  >
                                    <PencilIcon class="h-4 w-4" aria-hidden="true" />
                                  </button>
                                  <button
                                    v-tippy="{ content: 'Delete' }"
                                    class="ml-2 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 opacity-0 group-hover:opacity-100 text-gray-500 hover:text-blue-600"
                                    @click.prevent="removeOption(option)"
                                  >
                                    <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                                  </button>
                                </div>
                              </div>
                            </template>
                          </Draggable>
                        </div>
                        <SimpleEmptyState
                          v-else
                          class="pt-4 pb-8"
                          heading="No options yet"
                          description="Add some options to this variant"
                          icon="ClipboardDocumentListIcon"
                        />
                      </div>
                    </template>
                  </Draggable>
                </div>
                <SimpleEmptyState
                  v-else
                  class="mt-4 mb-6"
                  heading="No variants yet"
                  description="Get started by adding product variants"
                  icon="ClipboardDocumentListIcon"
                />
              </BasicContainer>
              <BasicContainer>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-12">
                    <div class="mb-3">
                      <div class="flex justify-between mb-3">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Stores</h3>
                        <Button :is-primary="true" @click.prevent="addPair">+ Store</Button>
                      </div>
                      <ul class="divide-y divide-gray-200">
                        <li v-for="pair in vLocal.pairs" :key="pair.id" class="group pt-2 mt-2 px-2">
                          <div class="flex">
                            <div
                              v-if="vStoreImage(mapRelationship(pair.relationships.store.data))"
                              class="w-10 h-10 mr-2 flex-shrink-0"
                            >
                              <div class="aspect-w-8 aspect-h-8">
                                <img
                                  class="w-full h-full object-center object-cover rounded select-none"
                                  :src="vStoreImage(mapRelationship(pair.relationships.store.data))"
                                />
                              </div>
                            </div>
                            <div class="flex flex-1 space-x-4">
                              <div class="flex-1 min-w-0">
                                <button
                                  class="text-left transition-colors duration-150 text-gray-700 hover:text-blue-600"
                                  @click.prevent="editPair(pair)"
                                >
                                  <p
                                    class="text-sm font-medium max-w-xxs sm:max-w-sm md:max-w-xxs lg:max-w-md truncate"
                                  >
                                    {{ mapRelationship(pair.relationships.store.data).attributes.store_name }}
                                  </p>
                                  <p
                                    class="text-sm text-gray-500 max-w-xxs sm:max-w-sm md:max-w-xxs lg:max-w-md truncate"
                                  >
                                    {{ mapRelationship(pair.relationships.category.data).attributes.category_name }}
                                  </p>
                                  <!-- <div class="flex justify-start items-center text-xs text-gray-500 mt-1">
                                              <span class="text-gray-500 font-small">
                                                {{ vPairInfo(pair).tiers }}
                                              </span>
                                              <span class="text-gray-500 font-small mx-2">·</span>
                                              <span v-if="vPairInfo(pair).taxable">
                                                <span class="text-gray-500 font-small">Taxable</span>
                                                <span class="text-gray-500 font-small mx-2">·</span>
                                              </span>
                                              <span class="text-gray-500 font-small">{{ vPairInfo(pair).labels }}</span>
                                            </div> -->
                                </button>
                              </div>
                              <div>
                                <button
                                  v-tippy="{ content: 'Edit' }"
                                  class="inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-700 transition-all duration-150 opacity-0 group-hover:opacity-100 text-gray-500 hover:text-blue-600"
                                  @click.prevent="editPair(pair)"
                                >
                                  <PencilIcon class="h-4 w-4" aria-hidden="true" />
                                </button>

                                <button
                                  v-tippy="{ content: 'Remove' }"
                                  class="ml-1 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-700 transition-all duration-150 opacity-0 group-hover:opacity-100 text-gray-500 hover:text-blue-600"
                                  @click.prevent="deletePair(pair)"
                                >
                                  <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </BasicContainer>

              <ProductIngredients :id="id" />
              <TheModal
                v-if="pairModal.visible && pairModal.mode === 'edit'"
                :is-open="pairModal.visible"
                :modal-title="`Edit ${pairModal.content.product.attributes.product_name} in store`"
                @close-modal="pairModal.visible = false"
              >
                <template #main>
                  <Form class="grid grid-cols-12 gap-4" @submit.prevent="putPair">
                    <div class="col-span-12 border-gray-300 border-b pb-3">
                      <div class="flex items-center">
                        <router-link
                          v-if="vStoreImage(pairModal.content.store)"
                          :to="`/store/stores/${pairModal.content.store.id}`"
                          class="w-8 h-8 mr-2"
                        >
                          <div class="aspect-w-8 aspect-h-8">
                            <img
                              class="w-full h-full object-center object-cover rounded select-none"
                              :src="vStoreImage(pairModal.content.store)"
                            />
                          </div>
                        </router-link>
                        <div class="flex-1 min-w-0">
                          <router-link
                            :to="`/store/stores/${pairModal.content.store.id}`"
                            class="text-left transition-colors duration-150 text-gray-700 hover:text-blue-600"
                          >
                            <p class="text-md font-medium truncate">
                              {{ pairModal.content.store.attributes.store_name }}
                            </p>
                            <p class="text-sm text-gray-700">
                              {{ pairModal.content.store.attributes.store_description }}
                            </p>
                          </router-link>
                        </div>
                      </div>
                      <div class="flex flex-wrap items-center justify-start mt-2">
                        <span
                          v-for="label in pairModal.content.labels"
                          :key="label.id"
                          class="mr-1 mb-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                        >
                          {{ label.attributes.label_name }}
                        </span>
                      </div>
                    </div>
                    <!-- <div class="col-span-6">
                      <div class="flex items-center h-5">
                        <input
                          id="product-discounts"
                          v-model="vLocal.modal.content.hasDiscounts"
                          aria-describedby="product-discounts"
                          name="product-discounts"
                          checked
                          type="checkbox"
                          class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                        />
                        <label for="product-discounts" class="ml-3 block text-sm font-medium text-gray-700">
                          Allow discounts
                        </label>
                      </div>
                    </div> -->
                    <div class="col-span-12 transition-colors duration-150 hover:bg-gray-50 p-3">
                      <p class="block text-md font-medium text-gray-700">Settings</p>
                      <div class="col-span-6 m-2">
                        <div class="flex items-center h-5">
                          <input
                            id="product-taxable"
                            v-model="pairModal.content.taxable"
                            aria-describedby="product-taxable"
                            name="product-taxable"
                            checked
                            type="checkbox"
                            class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            @change="putPair"
                          />
                          <label for="product-taxable" class="ml-3 block text-sm font-medium text-gray-700">
                            Taxable
                          </label>
                        </div>
                      </div>
                      <div class="col-span-6 m-2">
                        <div class="flex items-center h-5">
                          <input
                            id="product-allows-autofill"
                            v-model="pairModal.content.allowsAutofill"
                            aria-describedby="product-allows-autofill"
                            name="product-allows-autofill"
                            checked
                            type="checkbox"
                            class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            @change="putPair"
                          />
                          <label for="product-allows-autofill" class="ml-3 block text-sm font-medium text-gray-700">
                            Include item in logic when autofilling orders
                          </label>
                        </div>
                      </div>
                      <div class="col-span-6 m-2">
                        <div class="flex items-center h-5">
                          <input
                            id="product-show-price"
                            v-model="pairModal.content.showPrice"
                            aria-describedby="product-show-price"
                            name="product-show-price"
                            checked
                            type="checkbox"
                            class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            @change="putPair"
                          />
                          <label for="product-show-price" class="ml-3 block text-sm font-medium text-gray-700">
                            Show price
                          </label>
                        </div>
                      </div>
                      <div class="col-span-6 m-2">
                        <FormLabel for="methodType" label="Product card style" />
                        <Field
                          v-model="pairModal.content.productCardStyle"
                          name="methodType"
                          :rules="fieldIsRequired"
                          :class="[
                            'border-gray-300 mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md'
                          ]"
                          as="select"
                          @change="putPair"
                        >
                          <option value="default">Image upper right corner</option>
                          <option value="enlarged-image">Full-bleed image</option>
                        </Field>
                      </div>
                      <div class="col-span-6 m-2">
                        <FormLabel for="quantityDisplay" label="Quantity display type" />
                        <Field
                          v-model="pairModal.content.quantityDisplay"
                          name="quantityDisplay"
                          :rules="fieldIsRequired"
                          :class="[
                            'border-gray-300 mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md'
                          ]"
                          as="select"
                          @change="putPair"
                        >
                          <option value="default">Default</option>
                          <option value="checkbox">Checkbox</option>
                        </Field>
                      </div>
                    </div>
                    <div class="col-span-12 transition-colors duration-150 hover:bg-gray-50 p-3">
                      <p class="block text-md font-medium text-gray-700">Autofill replacement</p>
                      <div class="p-2">
                        <p class="text-sm">Fallback product if this product is unavailable during autofill:</p>
                        <router-link
                          v-if="pairModal.content.fallback"
                          class="text-gray-900 hover:text-blue-600 transition-colors duration-150 text-left p-2 block"
                          :to="'/store/products/' + pairModal.content.fallback.id"
                        >
                          <p class="text-sm font-medium max-w-xs truncate">
                            {{ pairModal.content.fallback.attributes.product_name }}
                          </p>
                          <p
                            v-show="pairModal.content.fallback.attributes.description"
                            class="text-sm text-gray-500 max-w-sm truncate"
                          >
                            {{ pairModal.content.fallback.attributes.description }}
                          </p>
                        </router-link>
                        <span v-else class="text-sm">None</span>
                      </div>
                      <div class="m-2">
                        <p class="text-sm">Will replace these unavailable products during autofill:</p>
                        <router-link
                          v-for="product in pairModal.content.reverseFallback"
                          :key="product.id"
                          class="text-gray-900 hover:text-blue-600 transition-colors duration-150 text-left p-2 block"
                          :to="'/store/products/' + product.id"
                        >
                          <p class="text-sm font-medium max-w-xs truncate">
                            {{ product.attributes.product_name }}
                          </p>
                          <p v-show="product.attributes.description" class="text-sm text-gray-500 max-w-sm truncate">
                            {{ product.attributes.description }}
                          </p>
                        </router-link>
                        <span v-if="!pairModal.content.reverseFallback.length" class="text-sm">None</span>
                      </div>
                    </div>
                    <div class="col-span-12 transition-colors duration-150 hover:bg-gray-50 p-3">
                      <p class="block text-md font-medium text-gray-700">Production day availability</p>
                      <div class="sm:grid grid-cols-12 gap-2 m-2">
                        <div class="col-span-12 pb-4 gap-2">
                          <div class="flex justify-between items-center">
                            <div class="flex items-center h-5">
                              <input
                                id="alwaysAvailable"
                                v-model="pairModal.content.alwaysAvailable"
                                aria-describedby="alwaysAvailable"
                                name="alwaysAvailable"
                                checked
                                type="checkbox"
                                class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                @change="putPair"
                              />
                              <FormLabel
                                for="alwaysAvailable"
                                label="Always available"
                                class="ml-3"
                                :is-inline="true"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-if="!pairModal.content.alwaysAvailable" class="col-span-12 gap-2">
                          <div class="flex justify-between items-center">
                            <DatePicker
                              v-model="pairModal.content.newDay"
                              :available-dates="{ start: new Date(), end: null }"
                              class="w-full mr-2"
                              :popover="{ visibility: 'click' }"
                            >
                              <template #default="{ inputValue, inputEvents }">
                                <input
                                  class="bg-white border px-2 py-2 w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md"
                                  :value="inputValue"
                                  v-on="inputEvents"
                                />
                              </template>
                            </DatePicker>
                            <button class="btn-ghost" @click.prevent="postDay">Add</button>
                          </div>
                          <ul class="mt-4 divide-y divide-gray-200 space-y-4">
                            <li class="group">
                              <div class="flex-1 min-w-0">
                                <div
                                  v-for="day in vDays(pairModal.content.pair)"
                                  :key="day.id"
                                  class="text-gray-900 text-left my-2"
                                >
                                  <div class="flex justify-between">
                                    <p class="text-sm font-medium">
                                      {{ formatDay(day.attributes.date) }}
                                    </p>
                                    <button
                                      v-tippy="{ content: 'Delete' }"
                                      class="ml-1 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 opacity-0 group-hover:opacity-100 text-gray-500 hover:text-blue-600"
                                      @click.prevent="deleteDay(day)"
                                    >
                                      <XMarkIcon class="h-3 w-3" aria-hidden="true" />
                                    </button>
                                  </div>
                                  <div class="mt-2 sm:grid grid-cols-12 gap-2">
                                    <div class="col-span-6 gap-2">
                                      <div class="flex items-center h-5">
                                        <input
                                          :id="`hasInventoryLimit-${day.id}`"
                                          v-model="pairModal.content.hasInventoryLimit[day.id]"
                                          aria-describedby="hasInventoryLimit"
                                          name="hasInventoryLimit"
                                          type="checkbox"
                                          class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                          @change="putPair"
                                        />
                                        <FormLabel
                                          :for="`hasInventoryLimit-${day.id}`"
                                          label="Has inventory limit"
                                          class="ml-3"
                                          :is-inline="true"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="pairModal.content.hasInventoryLimit[day.id]" class="col-span-6 gap-2">
                                      <label for="inventoryLimit" class="block text-sm font-medium text-gray-700 mb-1">
                                        Inventory Limit
                                      </label>
                                      <div class="max-w-lg flex w-full rounded-md shadow-sm">
                                        <span
                                          class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                                        >
                                          <HashtagIcon class="h-4 w-4" />
                                        </span>
                                        <input
                                          id="inventoryLimit"
                                          v-model="pairModal.content.inventoryLimit[day.id]"
                                          type="text"
                                          name="inventoryLimit"
                                          class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                          @change="putPair"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- TODO:@alicja once backend is ready: <div class="col-span-12 transition-colors duration-150 hover:bg-gray-50 p-3">
                      <p class="block text-md font-medium text-gray-700 mb-2">Fulfillment Methods</p>
                      <div class="sm:grid grid-cols-12 gap-2">
                        <div
                          v-for="(method, i) in pairModal.content.fulfillmentMethods"
                          :key="i"
                          :class="[
                            { 'border-b border-gray-100': i + 1 != pairModal.content.fulfillmentMethods.length },
                            'relative pb-4 col-span-12 grid gap-2 grid-cols-12'
                          ]"
                        >
                          <div class="col-span-12 flex">
                            <div class="flex items-center h-5">
                              <input
                                :id="`method-${i}`"
                                v-model="method.isAvailable"
                                :value="method.name"
                                :name="`day-${i}`"
                                type="checkbox"
                                class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              />
                            </div>
                            <div class="ml-3 text-sm">
                              <label class="font-medium text-gray-700">
                                {{ method.name }}
                              </label>
                            </div>
                          </div>
                          <div :class="[{ 'opacity-50': !method.isAvailable }, 'col-span-12']">
                            <label class="block text-sm font-medium text-gray-700 mb-1">Availability</label>
                            <DatePicker
                              v-model="method.dateRange"
                              :available-dates="{ start: new Date(), end: null }"
                              is-range
                              :popover="{ visibility: method.isAvailable ? 'click' : 'hidden' }"
                              class=""
                            >
                              <template #default="{ inputValue, inputEvents }">
                                <div class="flex justify-between items-center">
                                  <input
                                    class="bg-white border px-2 py-1 rounded w-1/2 mr-1"
                                    :disabled="!method.isAvailable"
                                    :value="inputValue.start"
                                    v-on="inputEvents.start"
                                  />
                                  <input
                                    class="bg-white border px-2 py-1 rounded w-1/2 ml-1"
                                    :disabled="!method.isAvailable"
                                    :value="inputValue.end"
                                    v-on="inputEvents.end"
                                  />
                                </div>
                              </template>
                            </DatePicker>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-span-12 transition-colors duration-150 hover:bg-gray-50 p-3">
                      <p class="block text-md font-medium text-gray-700 mb-4">Membership Pricing</p>
                      <div
                        v-for="(price, i) in pairModal.content.membershipPrices"
                        :key="price.id"
                        :class="[
                          {
                            'border-b border-gray-100 pb-4 mb-2': i + 1 !== pairModal.content.membershipPrices.length
                          },
                          'grid grid-cols-12 gap-4'
                        ]"
                      >
                        <div class="col-span-6">
                          <div class="flex items-center mb-4">
                            <!-- <input
                              id="tier-availability"
                              v-model="price.isAvailable"
                              aria-describedby="tier-availability"
                              name="tier-availability"
                              checked
                              type="checkbox"
                              class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            /> -->
                            <label for="tier-availability" class="block text-sm font-medium text-gray-700">
                              {{ price.name }}
                            </label>
                          </div>
                          <div class="flex items-center">
                            <input
                              :id="`use-pricing-tiers-${i}`"
                              v-model="price.subject"
                              aria-describedby="use-pricing-tiers"
                              name="use-pricing-tiers"
                              checked
                              type="checkbox"
                              class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              @change="putPair"
                            />
                            <label :for="`use-pricing-tiers-${i}`" class="ml-3 block text-sm font-medium text-gray-700">
                              Subject to pricing tiers
                            </label>
                          </div>
                          <div class="flex items-center mt-5">
                            <input
                              :id="`available-for-membership-${i}`"
                              v-model="price.available"
                              aria-describedby="available-for-membership"
                              name="available-for-membership"
                              checked
                              type="checkbox"
                              class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              @change="putPair"
                            />
                            <label
                              :for="`available-for-membership-${i}`"
                              class="ml-3 block text-sm font-medium text-gray-700"
                            >
                              Available for membership tier
                            </label>
                          </div>
                          <div class="flex items-center mt-5">
                            <input
                              :id="`use-default-price-${i}`"
                              v-model="price.default"
                              aria-describedby="use-default-price"
                              name="use-default-price"
                              checked
                              type="checkbox"
                              class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              @change="putPair"
                            />
                            <label :for="`use-default-price-${i}`" class="ml-3 block text-sm font-medium text-gray-700">
                              Use default price
                            </label>
                          </div>
                        </div>
                        <!-- <div :class="[{ 'opacity-50': !price.isAvailable }, 'col-span-6']"> -->
                        <div class="col-span-6" v-if="!price.default">
                          <div class="mb-2">
                            <label for="tier-dollar-amount" class="block text-sm font-medium text-gray-700 mb-1">
                              Dollar amount
                            </label>
                            <div class="max-w-lg flex rounded-md shadow-sm">
                              <span
                                class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                              >
                                <CurrencyDollarIcon class="h-4 w-4" />
                              </span>
                              <input
                                id="tier-dollar-amount"
                                v-model="price.price"
                                type="text"
                                name="tier-dollar-amount"
                                class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                @change="putPair"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </template>
                <template #footer>
                  <button class="btn-primary justify-center mr-3" type="button" @click="pairModal.visible = false">
                    Done
                  </button>
                </template>
              </TheModal>
              <TheModal
                v-if="pairModal.visible && pairModal.mode === 'add'"
                :is-open="pairModal.visible"
                modal-title="Add product to a store"
                @close-modal="pairModal.visible = false"
              >
                <template #main>
                  <Form class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 transition-colors duration-150 hover:bg-gray-50">
                      <div class="col-span-6 my-2">
                        <FormLabel for="store" label="Store" />
                        <Field
                          v-model="pairModal.content.store"
                          name="store"
                          :rules="fieldIsRequired"
                          class="border-gray-300 mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          as="select"
                        >
                          <option v-for="store in pairModal.content.stores" :value="store.id">
                            {{ store.attributes.store_name }}
                          </option>
                        </Field>
                      </div>
                      <div class="col-span-6 my-2">
                        <FormLabel for="category" label="Category" />
                        <Field
                          v-model="pairModal.content.category"
                          name="category"
                          :rules="fieldIsRequired"
                          class="border-gray-300 mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          as="select"
                        >
                          <option v-for="category in vStoreCategories(pairModal.content.store)" :value="category.id">
                            {{ category.attributes.category_name }}
                          </option>
                        </Field>
                      </div>
                    </div>
                  </Form>
                </template>
                <template #footer>
                  <button class="btn-primary justify-center mr-3" type="button" @click="makePair">Add</button>
                </template>
              </TheModal>
            </MainCol>
            <SideCol class="col-span-12 lg:col-span-4">
              <BasicContainer>
                <h3 class="text-lg leading-6 font-medium text-gray-900">Images</h3>
                <Draggable :model-value="vLocal.images || vImages" item-key="id" @end="dragImage">
                  <template #item="{ element }">
                    <div class="relative">
                      <XMarkIcon
                        class="hover:text-blue-500 transition-colors duration-150 h-5 w-5 text-gray-400 absolute right-1 top-1 bg-white rounded-full cursor-pointer"
                        @click="deleteImage(element)"
                      />
                      <img :src="element.attributes.secure_url" class="my-4 rounded cursor-move" />
                    </div>
                  </template>
                </Draggable>
                <label class="btn-primary">
                  Add image
                  <input
                    type="file"
                    accept="image/*"
                    class="hidden"
                    @input="
                      e => {
                        saveImage(e.target.files[0], id)
                      }
                    "
                  />
                </label>
              </BasicContainer>
              <!-- Labels -->
              <BasicContainer>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-12">
                    <div class="mb-3">
                      <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">Labels</h3>
                      <div v-for="label in vAllLabels" :key="label.id" class="relative flex items-start mb-3">
                        <div class="flex items-center h-5">
                          <input
                            :id="label.attributes.label_name"
                            :aria-describedby="`label-${label.id}-description`"
                            :name="`label-${label.id}`"
                            type="checkbox"
                            class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            :checked="vLabelChecked(label)"
                            @click="toggleLabel(label)"
                          />
                        </div>
                        <div class="ml-3 text-sm">
                          <label :for="label.attributes.label_name" class="font-medium text-gray-700">
                            {{ label.attributes.label_name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BasicContainer>
              <BasicContainer>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-12">
                    <div class="mb-3">
                      <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">Actions</h3>
                      <NeueButton @click="copyProduct">Copy</NeueButton>
                    </div>
                  </div>
                </div>
              </BasicContainer>
            </SideCol>
          </PageGrid>
          <PageFooter>
            <template #left>
              <button class="btn-alert" type="button" @click.prevent="deleteProduct">Delete</button>
            </template>
            <template #right>
              <button v-if="vLocal.status.hasUnsavedChanges" class="btn-primary" type="submit">Save</button>
              <router-link v-else class="btn-primary" type="button" to="/store/products">Done</router-link>
            </template>
          </PageFooter>
        </Form>
        <!-- Confirm cancel modal -->
        <TheModal
          v-show="vLocal.modal.type === 'confirmCancelChangesStoreModal'"
          :is-open="vLocal.modal.type === 'confirmCancelChangesStoreModal'"
          modal-title="Abandon changes"
          modal-description="Are you sure you'd like to leave without saving your changes?"
          @close-modal="vLocal.modal.type = ''"
        >
          <template #footer>
            <button class="btn-alert justify-center mr-3" @click="handleConfirmCancelChanges()">Leave</button>
            <button class="btn-ghost justify-center" type="button" @click="resetModalContent()">Stay</button>
          </template>
        </TheModal>
      </div>
    </template>
  </SecondaryNav>
</template>
<script>
import { MenuItem } from '@headlessui/vue'
import {
  CheckIcon,
  CurrencyDollarIcon,
  HashtagIcon,
  InformationCircleIcon,
  PencilIcon,
  XMarkIcon
} from '@heroicons/vue/20/solid'
import * as R from 'ramda'
import { DatePicker } from 'v-calendar'
import { Field, Form } from 'vee-validate'
import { computed, onMounted, reactive } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import Draggable from 'vuedraggable'
import { useStore } from 'vuex'

import { dateObjectToServerDay, serverDayToDateObject } from '@/helpers/dates'
import CloudinaryService from '@/services/cloudinary.service'
import api from '@/store/api'
import { mapRelationship, mapRelationships } from '@/store/mappers'
import SellSidebar from '@/view/store/SellSidebar'

const UploadService = new CloudinaryService()

export default {
  components: {
    CurrencyDollarIcon,
    PencilIcon,
    XMarkIcon,
    Draggable,
    MenuItem,
    CheckIcon,
    Field,
    Form,
    DatePicker,
    HashtagIcon,
    InformationCircleIcon,
    SellSidebar
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    onBeforeRouteLeave(() => {
      if (vLocal.status.hasUnsavedChanges) {
        vLocal.modal.type = 'confirmCancelChangesStoreModal'
        return false
      } else return true
    })

    const id = route.params.id

    const vLocal = reactive({
      name: '',
      defaultPrice: '',
      accountCreditValue: '',
      description: '',
      image: {},
      lastSavedImage: '',
      taxInputSearch: '',
      sku: '',
      uniqueQuantityNameSingle: '',
      startingQuantity: '',
      step: '',
      hasQuantityLimit: false,
      quantityLimit: null,
      modal: {
        type: null,
        mode: null,
        content: {}
      },
      allProducts: [],
      images: null,
      variants: null,
      options: {},
      status: {
        fallbackProduct: {
          isLoading: false,
          hasError: false
        },
        hasUnsavedChanges: false
      },
      pairs: []
    })

    const vStore = useStore()

    const vProduct = computed(() => mapRelationship({ type: 'product', id }))

    const vImage = product => {
      const images = mapRelationships(product?.relationships?.attachments.data)
      return images[0]?.attributes ? images[0].attributes.secure_url : null
    }

    const vImages = computed(() =>
      mapRelationships(vProduct.value?.relationships.attachments.data).sort(
        (a, b) => a.attributes.order - b.attributes.order
      )
    )

    const vStoreImage = store => {
      const images = mapRelationships(store?.relationships?.attachments.data)
      return images[0]?.attributes ? images[0].attributes.secure_url : null
    }

    const allProductTaxCodes = computed(() => {
      if (!vStore.state?.objects?.product_tax_code) {
        return [{ code: '', name: 'None', description: 'None' }]
      }

      return [{ code: '', name: 'None', description: 'None' }].concat(
        Object.values(vStore.state?.objects?.product_tax_code)
          .map(taxCode => {
            return {
              id: taxCode.attributes.id,
              code: taxCode.attributes.code,
              description: taxCode.attributes.description,
              name: taxCode.attributes.name
            }
          })
          .filter(
            taxCode =>
              taxCode.name.toLowerCase().includes(vLocal.taxInputSearch.toLowerCase()) ||
              taxCode.code.toLowerCase().includes(vLocal.taxInputSearch.toLowerCase())
          )
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      )
    })

    const getAllProducts = () => {
      api.call('GET', 'products?included=attachments').then(response => {
        vLocal.allProducts = response.data.data
      })
    }

    const getAllTaxProductTaxCodes = () => {
      api.call('GET', 'products/tax_codes')
    }

    const taxSearchInputChanged = value => {
      vLocal.taxInputSearch = value
    }

    const taxOptionSelected = option => {
      vLocal.status.hasUnsavedChanges = true
      vLocal.taxSelectedCode = option.code
    }

    const indexForSelectedTaxOption = allTaxProducts => {
      if (!vLocal.taxSelectedCode) {
        return 0
      }

      return allTaxProducts.findIndex(taxObject => {
        return taxObject.code === vLocal.taxSelectedCode
      })
    }

    const taxCodeForProduct = product => {
      if (product?.name === 'None') return ''
      return `#${product?.code}`
    }

    const vVariants = computed(() => mapRelationships(vProduct.value?.relationships.variants.data))

    const vOptions = variant => mapRelationships(variant.relationships.variant_options.data)

    const vAllLabels = computed(() => vStore.state.objects.label && Object.values(vStore.state.objects.label))

    const vLabelChecked = label => {
      const labelPairs = mapRelationships(vProduct.value?.relationships.label_product_pairs.data)
      return labelPairs.some(x => x.relationships.label.data.id === label.id)
    }

    const optionModal = reactive({
      visible: false,
      variant: null,
      option: null,
      mode: '',
      name: '',
      matchName: '',
      description: '',
      price: '0.00'
    })

    const variantModal = reactive({
      visible: false,
      variant: null,
      mode: '',
      name: '',
      required: true,
      quantity: 'choose_one',
      minimum: 1,
      maximum: 100,
      customerVisible: true
    })

    const pairModal = reactive({ mode: '', visible: false, content: {} })

    const addVariant = () => {
      variantModal.mode = 'add'
      variantModal.visible = true
    }

    const removeVariant = variant => {
      api.call('PUT', 'products/' + route.params.id, {
        product: { variants_attributes: [{ id: variant.id, _destroy: true }] }
      })
    }

    const resetOptionModal = () => {
      optionModal.visible = false
      optionModal.name = ''
      optionModal.matchName = ''
      optionModal.description = ''
      optionModal.price = '0.00'
    }

    const resetVariantModal = () => {
      variantModal.visible = false
      variantModal.name = ''
      variantModal.required = true
      variantModal.quantity = 'choose_one'
      variantModal.minimum = 1
      variantModal.maximum = 100
      variantModal.customerVisible = true
    }

    const addOption = variant => {
      optionModal.mode = 'add'
      optionModal.variant = variant
      optionModal.visible = true
    }

    const editOption = option => {
      optionModal.mode = 'edit'
      optionModal.name = option.attributes.name
      optionModal.matchName = option.attributes.autofill_preference_override
      optionModal.description = option.attributes.description
      optionModal.price = (option.attributes.price.cents / 100).toFixed(2)
      optionModal.option = option
      optionModal.visible = true
    }

    const editVariant = variant => {
      variantModal.mode = 'edit'
      variantModal.name = variant.attributes.name
      variantModal.required = variant.attributes.required
      variantModal.quantity = variant.attributes.quantity_option
      variantModal.variant = variant
      variantModal.visible = true
      variantModal.minimum = variant.attributes.minimum
      variantModal.maximum = variant.attributes.maximum
      variantModal.customerVisible = variant.attributes.is_visible
    }

    const addPair = () => {
      pairModal.mode = 'add'
      pairModal.content.stores = Object.values(vStore.state.objects.store || {}).filter(x => x.attributes.is_active)
      pairModal.visible = true
    }

    const vStoreCategories = storeId => {
      const store = mapRelationship({ type: 'store', id: storeId })
      return mapRelationships(store?.relationships.categories.data)
    }

    const editPair = pair => {
      pairModal.mode = 'edit'
      const rawPrices = mapRelationships(pair.relationships.product_store_membership_prices.data)
      const prices = rawPrices.map(price => {
        const tier = mapRelationship(price.relationships.membership_tier.data)
        return {
          priceId: price.id,
          tierId: tier.id,
          name: tier.attributes.tier_name,
          price: price.attributes.price.cents / 100,
          subject: price.attributes.subject_to_pricing_tiers,
          available: price.attributes.available_for_membership_tier,
          default: price.attributes.use_default_price
        }
      })
      pairModal.content = {
        pair,
        store: mapRelationship(pair.relationships.store.data),
        product: vPairProduct(pair),
        image: vImage(vPairProduct(pair)),
        labels: mapRelationships(vPairProduct(pair).relationships.labels.data),
        membershipPrices: prices,
        taxable: pair.attributes.taxable,
        allowsAutofill: pair.attributes.allows_autofill,
        showPrice: pair.attributes.show_prices,
        productCardStyle: pair.attributes.product_card_style,
        quantityDisplay: pair.attributes.quantity_selector_type,
        alwaysAvailable: pair.attributes.available_for_all_fulfillment_availabilities,
        newDay: new Date(),
        hasInventoryLimit: Object.fromEntries(vDays(pair).map(x => [x.id, x.attributes.limit_quantity])),
        inventoryLimit: Object.fromEntries(vDays(pair).map(x => [x.id, x.attributes.quantity_limit])),
        fallback: mapRelationship(vPairProduct(pair).relationships.replace_with_product.data),
        reverseFallback: mapRelationships(vPairProduct(pair).relationships.replaces_products.data)
      }
      // isTaxable: categories.visibleCategories[i].products[ii].hasTaxable,
      // hasDiscounts: categories.visibleCategories[i].products[ii].hasDiscounts,
      // fulfillmentMethods: categories.visibleCategories[i].products[ii].fulfillmentMethods,
      pairModal.visible = true
    }

    const makePair = () => {
      api
        .call('POST', `stores/${pairModal.content.store}/category_product_store_pairs`, {
          category_id: pairModal.content.category,
          product_id: vProduct.value.id
        })
        .then(() => {
          // TODO(alicja): the proper solution is to change mapRel behavior
          // so we can have the list of stores as computed instead of loading it in getProduct
          getProduct()
        })
      pairModal.visible = false
    }

    const putPair = () => {
      api.call(
        'PUT',
        `stores/${pairModal.content.pair.relationships.store.data.id}/category_product_store_pairs/${pairModal.content.pair.id}?included=fulfillment_availabilities,product_store_membership_prices`,
        {
          category_product_store_pair: {
            taxable: pairModal.content.taxable,
            allows_autofill: pairModal.content.allowsAutofill,
            show_prices: pairModal.content.showPrice,
            product_card_style: pairModal.content.productCardStyle,
            quantity_selector_type: pairModal.content.quantityDisplay,
            available_for_all_fulfillment_availabilities: pairModal.content.alwaysAvailable,
            product_store_membership_prices_attributes: pairModal.content.membershipPrices.map(x => ({
              id: x.priceId,
              membership_tier_id: x.tierId,
              price_cents: Math.round(x.price * 100),
              subject_to_pricing_tiers: x.subject,
              available_for_membership_tier: x.available,
              use_default_price: x.default
            })),
            fulfillment_availabilities_attributes: vDays(pairModal.content.pair).map(x => ({
              id: x.id,
              quantity_limit: pairModal.content.inventoryLimit[x.id],
              limit_quantity: pairModal.content.hasInventoryLimit[x.id]
            }))
          }
        }
      )
    }

    const deletePair = async pair => {
      await api.call(
        'DELETE',
        `stores/${pair.relationships.store.data.id}/category_product_store_pairs/${pair.id}`,
        {},
        { delete: false }
      )
      // TODO(alicja): the proper solution is to change mapRel behavior
      // so we can have the list of stores as computed instead of loading it in getProduct
      getProduct()
    }

    const formatDay = x => new Intl.DateTimeFormat([], { dateStyle: 'full' }).format(serverDayToDateObject(x))

    const postDay = () => {
      api
        .call(
          'PUT',
          `stores/${pairModal.content.pair.relationships.store.data.id}/category_product_store_pairs/${pairModal.content.pair.id}?included=fulfillment_availabilities`,
          {
            category_product_store_pair: {
              fulfillment_availabilities_attributes: [
                {
                  date: dateObjectToServerDay(pairModal.content.newDay)
                }
              ]
            }
          }
        )
        .then(response => {
          pairModal.content.pair = response.data.data
        })
    }

    const deleteDay = day => {
      api
        .call(
          'PUT',
          `stores/${pairModal.content.pair.relationships.store.data.id}/category_product_store_pairs/${pairModal.content.pair.id}`,
          {
            category_product_store_pair: {
              fulfillment_availabilities_attributes: [
                {
                  id: day.id,
                  _destroy: true
                }
              ]
            }
          }
        )
        .then(response => {
          pairModal.content.pair = response.data.data
        })
    }

    const vPairProduct = pair => vStore.state.objects.product[pair.attributes.product_id]

    const vDays = pair => mapRelationships(pair.relationships.fulfillment_availabilities.data)

    const saveOption = () => {
      if (optionModal.mode === 'add') {
        api.call('PUT', 'products/' + route.params.id, {
          product: {
            variants_attributes: [
              {
                id: optionModal.variant.id,
                variant_options_attributes: [
                  {
                    name: optionModal.name,
                    autofill_preference_override: optionModal.matchName,
                    description: optionModal.description,
                    price_cents: Math.round(optionModal.price * 100)
                  }
                ]
              }
            ]
          }
        })
      }
      if (optionModal.mode === 'edit') {
        const variant = mapRelationship(optionModal.option.relationships.variant.data)
        api.call('PUT', 'products/' + route.params.id, {
          product: {
            variants_attributes: [
              {
                id: variant.id,
                variant_options_attributes: [
                  {
                    id: optionModal.option.id,
                    name: optionModal.name,
                    autofill_preference_override: optionModal.matchName,
                    description: optionModal.description,
                    price_cents: Math.round(optionModal.price * 100)
                  }
                ]
              }
            ]
          }
        })
      }

      resetOptionModal()
    }

    const saveVariant = () => {
      if (variantModal.mode === 'add') {
        api.call('PUT', 'products/' + route.params.id, {
          product: {
            variants_attributes: [
              {
                name: variantModal.name,
                required: variantModal.required,
                quantity_option: variantModal.quantity,
                minimum: variantModal.minimum,
                maximum: variantModal.maximum,
                is_visible: variantModal.customerVisible
              }
            ]
          }
        })
      }
      if (variantModal.mode === 'edit') {
        api.call('PUT', 'products/' + route.params.id, {
          product: {
            variants_attributes: [
              {
                id: variantModal.variant.id,
                name: variantModal.name,
                required: variantModal.required,
                quantity_option: variantModal.quantity,
                minimum: variantModal.minimum,
                maximum: variantModal.maximum,
                is_visible: variantModal.customerVisible
              }
            ]
          }
        })
      }

      resetVariantModal()
    }

    const toggleLabel = label => {
      const labelPairs = mapRelationships(vProduct.value.relationships.label_product_pairs.data)
      const pair = labelPairs.find(x => x.relationships.label.data.id === label.id)
      if (pair) {
        // uncheck
        api.call('PUT', 'products/' + route.params.id, {
          product: { label_product_pairs_attributes: [{ id: pair.id, _destroy: true }] }
        })
      } else {
        // check
        api.call('PUT', 'products/' + route.params.id, {
          product: { label_product_pairs_attributes: [{ label_id: label.id }] }
        })
      }
    }

    const removeOption = option => {
      const variant = mapRelationship(option.relationships.variant.data)
      api.call('PUT', 'products/' + route.params.id, {
        product: {
          variants_attributes: [{ id: variant.id, variant_options_attributes: [{ id: option.id, _destroy: true }] }]
        }
      })
    }

    const handleSubmit = () => {
      api
        .call('PUT', 'products/' + route.params.id, {
          product: {
            product_name: vLocal.name,
            default_price_cents: Math.round(vLocal.defaultPrice * 100),
            account_credit_value_cents: Math.round(vLocal.accountCreditValue * 100),
            description: vLocal.description,
            merchant_sku: vLocal.sku,
            unique_quantity_name_single: vLocal.uniqueQuantityNameSingle,
            starting_quantity: vLocal.startingQuantity,
            step: vLocal.step,
            tax_code: vLocal.taxSelectedCode,
            has_quantity_limit: vLocal.hasQuantityLimit,
            requires_fulfillment: vLocal.requiresFulfillment,
            set_quantity_changes_attributes: [
              {
                value: vLocal.quantityLimit
              }
            ]
          }
        })
        .then(() => {
          vLocal.status.hasUnsavedChanges = false
        })
    }

    const deleteProduct = () => {
      api.call('DELETE', 'products/' + route.params.id).then(router.back)
    }

    const copyProduct = () => {
      api.call('POST', `products/${route.params.id}/copies`).then(async response => {
        await router.push(response.data.data.id)
        router.go(0)
      })
    }

    const deleteImage = attachment => {
      api.call('PUT', `products/${id}`, {
        product: {
          attachments_attributes: [{ id: attachment.id, _destroy: true }]
        }
      })
    }

    const saveImage = (file, id) => {
      const maxOrder = vImages.value.reduce((max, x) => Math.max(max, x.attributes.order), -1)

      UploadService.uploadImages([file]).then(response => {
        api.call('PUT', `products/${id}`, {
          product: {
            attachments_attributes: [
              {
                ...response[0],
                order: maxOrder + 1
              }
            ]
          }
        })
      })
    }

    const dragImage = e => {
      const list = vImages.value
      const moved = list[e.oldIndex]
      list.splice(e.oldIndex, 1)
      list.splice(e.newIndex, 0, moved)
      vLocal.images = list
      api
        .call('PUT', `products/${id}`, {
          product: {
            attachments_attributes: list.map((x, i) => ({
              id: x.id,
              order: i
            }))
          }
        })
        .then(() => {
          vLocal.images = null
        })
    }

    const dragVariant = e => {
      const list = vVariants.value
      const moved = list[e.oldIndex]
      list.splice(e.oldIndex, 1)
      list.splice(e.newIndex, 0, moved)
      vLocal.variants = list
      api
        .call('PUT', `products/${id}`, {
          product: {
            variants_attributes: list.map((x, i) => ({
              id: x.id,
              order: i
            }))
          }
        })
        .then(() => {
          vLocal.variants = null
        })
    }

    const dragOption = (variant, e) => {
      const list = vOptions(variant)
      const moved = list[e.oldIndex]
      list.splice(e.oldIndex, 1)
      list.splice(e.newIndex, 0, moved)
      vLocal.options[variant.id] = list
      api
        .call('PUT', `products/${id}`, {
          product: {
            variants_attributes: [
              {
                id: variant.id,
                variant_options_attributes: list.map((x, i) => ({
                  id: x.id,
                  order: i
                }))
              }
            ]
          }
        })
        .then(() => {
          vLocal.options[variant.id] = null
        })
    }

    const resetModalContent = () => {
      vLocal.modal.content = {}
      vLocal.modal.mode = 'add'
      vLocal.modal.type = null
    }

    const addFallbackProduct = () => {
      vLocal.modal.mode = 'add'
      vLocal.modal.type = 'fallbackProductModal'
      vLocal.modal.content = {
        fallbackProduct: []
      }
    }

    const saveFallbackProduct = productId => {
      const replaceWith = productId ? vLocal.modal.content.fallbackProduct[0].id : null
      const includes = ['replace_with_product']

      vLocal.status.fallbackProduct.isLoading = true

      api
        .call('PUT', `products/${id}?store=true&included=${includes.join(',')}`, {
          product: {
            replace_with_product_id: replaceWith
          }
        })
        .then(response => {
          const attributes = response.data.data.attributes

          vLocal.fallbackProduct = mapRelationship({ type: 'product', id: attributes.replace_with_product_id })

          vLocal.status.fallbackProduct.isLoading = false
          resetModalContent()
        })
        .catch(error => {
          vLocal.status.fallbackProduct.isLoading = false
          vLocal.status.fallbackProduct.hasError = false
          resetModalContent()
        })
    }

    const getProduct = () => {
      const includes = [
        'attachments',
        'variants',
        'variants.variant_options',
        'label_product_pairs',
        'replace_with_product',
        'category_product_store_pairs',
        'category_product_store_pairs.store',
        'category_product_store_pairs.store.attachments',
        'category_product_store_pairs.category',
        'category_product_store_pairs.product_store_membership_prices',
        'category_product_store_pairs.store.user.membership_tiers',
        'ingredients'
      ]
      api.call('GET', `products/${id}?store=true&included=${includes.join(',')}`).then(response => {
        const attributes = response.data.data.attributes
        const included = response.data.included

        vLocal.lastSavedImage = included.filter(x => x.type === 'attachment')[0]?.attributes.secure_url
        vLocal.name = attributes.product_name
        vLocal.type = attributes.type
        vLocal.defaultPrice = attributes.default_price?.cents ? (attributes.default_price?.cents / 100).toFixed(2) : ''
        vLocal.accountCreditValue = attributes.account_credit_value?.cents
          ? (attributes.account_credit_value?.cents / 100).toFixed(2)
          : ''
        vLocal.description = attributes.description
        vLocal.sku = attributes.merchant_sku
        vLocal.step = attributes.step
        vLocal.startingQuantity = attributes.starting_quantity
        vLocal.uniqueQuantityNameSingle = attributes.unique_quantity_name_single
        vLocal.quantityLimit = attributes.inventory_remaining
        vLocal.hasQuantityLimit = attributes.has_quantity_limit
        vLocal.requiresFulfillment = attributes.requires_fulfillment
        vLocal.fallbackProduct = mapRelationship({ type: 'product', id: attributes.replace_with_product_id })
        vLocal.taxSelectedCode = attributes.tax_code

        const pairStoreValid = R.pipe(
          R.path(['relationships', 'store', 'data']),
          mapRelationship,
          R.path(['attributes', 'is_active'])
        )

        // pair -> store -> categories -> ids
        const pairToCategoriesIds = R.pipe(
          R.path(['relationships', 'store', 'data']),
          mapRelationship,
          R.path(['relationships', 'categories', 'data']),
          R.pluck('id')
        )
        // pair -> category -> id
        const pairToCategoryId = R.path(['relationships', 'category', 'data', 'id'])
        const pairs = mapRelationships(response.data.data.relationships.category_product_store_pairs.data)
        // set pairs and filter for valid ones (still existing on the store)
        vLocal.pairs = pairs.filter(
          pair => pairToCategoriesIds(pair).includes(pairToCategoryId(pair)) && pairStoreValid(pair)
        )
      })
    }

    const getLabels = () => {
      api.call('GET', 'labels')
    }

    const getStores = () => {
      api.call('GET', 'stores?included=categories')
    }

    const handleKeydown = event => {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    }

    const handleProductSearchQuery = searchQuery => (vLocal.modal.searchQuery = searchQuery)
    const handleProductSearchResults = searchResults => (vLocal.modal.searchResults = searchResults)
    const handleProductSearchSelected = product => {
      vLocal.modal.content.fallbackProduct = [product]
    }
    const handleProductSearchRemoveSelected = () => {
      vLocal.modal.content.fallbackProduct = []
    }
    const searchSelectHelperText = {
      noResult: 'No results found',
      noneSelected: 'Select items',
      typingPrompt: 'Chicken Parmesan'
    }

    const handleDiscardChanges = async () => {
      // location.reload()
      await getProduct()
      vLocal.status.hasUnsavedChanges = false
    }

    const handleConfirmCancelChanges = () => {
      vLocal.status.hasUnsavedChanges = false
      router.push('/store/products')
    }

    const fieldIsRequired = val => {
      return val ? true : 'This field is required'
    }

    onMounted(() => {
      getLabels()
      getProduct()
      getAllProducts()
      getAllTaxProductTaxCodes()
      getStores()
    })

    return {
      vLocal,
      vImage,
      handleSubmit,
      addVariant,
      removeVariant,
      optionModal,
      variantModal,
      addOption,
      saveOption,
      saveVariant,
      resetOptionModal,
      resetVariantModal,
      removeOption,
      editOption,
      editVariant,
      saveImage,
      vVariants,
      vOptions,
      vAllLabels,
      toggleLabel,
      vLabelChecked,
      handleKeydown,
      deleteProduct,
      addFallbackProduct,
      saveFallbackProduct,
      resetModalContent,
      id,
      vImages,
      deleteImage,
      dragImage,
      dragVariant,
      dragOption,
      handleProductSearchQuery,
      handleProductSearchResults,
      handleProductSearchSelected,
      handleProductSearchRemoveSelected,
      searchSelectHelperText,
      handleDiscardChanges,
      handleConfirmCancelChanges,
      getAllTaxProductTaxCodes,
      allProductTaxCodes,
      taxSearchInputChanged,
      taxOptionSelected,
      indexForSelectedTaxOption,
      taxCodeForProduct,
      mapRelationship,
      vStoreImage,
      editPair,
      pairModal,
      vPairProduct,
      vDays,
      putPair,
      deletePair,
      fieldIsRequired,
      postDay,
      deleteDay,
      formatDay,
      makePair,
      addPair,
      vStoreCategories,
      copyProduct
    }
  }
}
</script>
